import type { ComponentType } from "react"
import React, { useRef, useEffect } from "react"
import { Override } from "framer"

export const AdjustGridChildren: Override = (props) => {
    const gridRef = useRef<HTMLElement | null>(null)

    useEffect(() => {
        // apply only to desktop
        if (window.innerWidth < 1200) {
            return
        }

        const applyChanges = () => {
            const grid = gridRef.current
            if (!grid) return

            const children = grid.children
            for (let i = 0; i < children.length; i++) {
                const child = children[i] as HTMLElement
                if (i % 2 === 0) {
                    child.style.marginBottom = "220px"
                    child.style.paddingBottom = "0px"
                } else {
                    child.style.marginTop = "220px"
                    child.style.paddingBottom = "0px"
                }
            }
        }
        // catching DOM element as Framer probably uses its own method
        const observer = new MutationObserver(applyChanges)
        const grid = document.querySelector(
            '[data-framer-name="Case Studies - List"]'
        )
        if (grid) {
            gridRef.current = grid as HTMLElement
            observer.observe(gridRef.current, {
                childList: true,
                subtree: true,
            }) // checking childs behavior
        }
        applyChanges()

        return () => {
            if (gridRef.current) {
                observer.disconnect()
            }
        }
    }, []) // run when component mounted only

    return {}
}
